import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Button, Typography } from '@material-ui/core'
import productAPI from '../../../../../api/product'
import Container from '../../../../Reusable/Container'
import Fixed from '../../../../Reusable/Fixed'
import PageHeading from '../../../../Reusable/PageHeading'
import PageTitle from '../../../../Reusable/PageTitle'
import Scrollable from '../../../../Reusable/Scrollable'
import { PaletteColors } from '../../../../../config/colors'
import { formatContactNo, formatLocation } from '../../../../../helpers/util'
import TableHead from '../../../../Reusable/TableHead'
import TableBody from '../../../../Reusable/TableBody'
import Loading from '../../../../Order/Tabs/Loading'
import useAPILocal from '../../../../../hooks/useAPILocal'
import VisibilityModal from '../Seller/VisibilityModal'
import Modal from '../../../../Modal'
import TextInput from '../../../../Reusable/Widgets/TextInput'
import useForm from '../../../../../hooks/useForm'
import matchersAPI from '../../../../../api/matchers'
import AutoCompleteInput from '../../../../Reusable/Widgets/AutoCompleteInput'
import { toast } from 'react-toastify'

const fields = [
  { label: 'Product Name', value: e => e?.name },
  { label: 'UN Number', value: e => e?.unNumber },
  {
    label: 'Containing Tanks',
    value: e => e?.length,
  },
]

const Item = ({ label, value }) => {
  return (
    <Box>
      <Box>
        <Typography
          style={{ fontSize: 14, color: PaletteColors.textMedium }}
          variant="caption"
        >
          {label}
        </Typography>
        <Typography
          style={{ fontSize: 16, color: PaletteColors.textDark }}
          variant="body1"
        >
          {value}
        </Typography>
      </Box>
    </Box>
  )
}

export default function ProductDetailsPage() {
  const param = useParams()
  const [productInfo, setProductInfo] = useState([])
  const [tanksInfo, setTanksInfo] = useState([])
  const [data, setData] = useState()
  const [isOpenInvitation, setIsOpenInvitation] = useState(false)
  const toggleModalInvitation = () => {
    setIsOpenInvitation(!isOpenInvitation)
  }
  const history = useHistory()
  const path = useParams()

  const partnerId = history?.location?.state?.partnerId
  const productInfoApi = useAPILocal(productAPI.info)
  const productsTanksApi = useAPILocal(productAPI.productTanks)
  const getProductMatchingStrings = useAPILocal(
    matchersAPI.getProductMatchingStrings
  )
  const updateProductMatchingStrings = useAPILocal(
    matchersAPI.updateProductMatchingStrings
  )

  const getProductInfo = () => {
    productInfoApi.request(path.id).then(res => setProductInfo(res.data))
  }

  const getTanksInfo = () => {
    if (path.id)
      productsTanksApi
        .request({ productId: path.id, search: '' })
        .then(res => setTanksInfo(res.data?.results))
  }

  useEffect(() => {
    getProductInfo()
    getTanksInfo()
  }, [path.id])

  const [matchers, setMatchers] = useState()

  const [isOpenMatcher, setIsOpenMatcher] = useState(false)

  const toggleModalMatcher = () => {
    setIsOpenMatcher(!isOpenMatcher)
  }
  const onCloseMatcher = () => {
    setIsOpenMatcher(false)
  }

  const init = {
    code: '',
  }

  const validationSchema = {
    code: ['required'],
  }

  const { errors, handleOnChange, values, resetForm, handleSubmit, setValues } =
    useForm(init, validationSchema, async result => {
      const strings = result.code.split('\n').filter(item => item.length !== 0)
      updateProductMatchingStrings.request(
        partnerId,
        param.id,
        strings,
        result?.myProduct?.id
      )
        .then(() => {
          resetForm()
          toast.success('Matcher strings updated successfully')
        })
        .catch(error => {
          toast.error(error?.clientMessage || error?.message || 'Something went wrong')
        })
    })

  useEffect(() => {
    setValues({
      code: matchers?.map(item => item.matcher).join('\n'),
      myProduct: matchers?.[0]?.myProduct,
    })
  }, [matchers])

  useEffect(() => {
    if (partnerId)
      getProductMatchingStrings
        .request(partnerId, param.id)
        .then(res => setMatchers(res.data))
  }, [updateProductMatchingStrings.isResolved])

  if (productInfoApi.isPending) {
    return <Loading fullScreen />
  }

  const columns = [
    {
      label: 'Tank No./Name',
      value: e => e.tankRegistrationNo,
    },
    {
      label: 'Product Details',
      value: e =>
        e?.products.length !== 0
          ? e?.products?.map(item => `${item?.name}\n`)
          : '-',
    },
    {
      label: 'Location',
      value: e => formatLocation(e?.location),
    },
    {
      label: 'Tank Type',
      value: e => {
        const maps = { loadingTank: 'Loading', unloadingTank: 'Unloading' }
        return Object.keys(maps)
          .filter(r => e[r])
          .map(p => maps[p])
          .join(' / ')
      },
    },
    {
      label: 'Visibility',
      value: e => {
        return (
          <Button
            onClick={event => {
              event.stopPropagation()
              setData(e)
              setIsOpenInvitation(!isOpenInvitation)
            }}
            variant="outlined"
            color="secondary"
          >
            {e?.visibility.replaceAll('_', ' ')}
          </Button>
        )
      },
    },
  ]

  const additionalProps = name => {
    return {
      value: values[name],
      error: errors[name],
      onChange: newValue => {
        handleOnChange({
          name,
          value: newValue,
        })
      },
    }
  }

  return (
    <Container pt={3}>
      <Fixed px={3}>
        <PageHeading mb={2}>
          <PageTitle backButton title={productInfo?.name} />
          {partnerId && (
            <Button
              onClick={toggleModalMatcher}
              variant="contained"
              color="primary"
            >
              Edit ERP/TMS
            </Button>
          )}
        </PageHeading>
        <Box
          className="shipments-list"
          style={{
            backgroundColor: PaletteColors.backgroundPrimary,
            borderRadius: 9,
          }}
        >
          <div className="productbox">
            <div className="productboxin">
              {productInfoApi.isPending ? (
                <Loading m={4} />
              ) : (
                <>
                  {fields.map(field => (
                    <Box key={field.label}>
                      <Item
                        label={field.label}
                        value={
                          field.label === 'Containing Tanks'
                            ? field.value(tanksInfo)
                            : field.value(productInfo)
                        }
                      />
                    </Box>
                  ))}
                  {partnerId && (
                    <Box>
                      <Typography
                        style={{
                          fontSize: 14,
                          color: PaletteColors.textMedium,
                        }}
                        variant="caption"
                      >
                        ERP/TMS Number(s)
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 16,
                          color: PaletteColors.textDark,
                        }}
                        variant="body1"
                      >
                        {matchers?.length !== 0
                          ? matchers?.map(item => item?.matcher).join(', ')
                          : '-'}
                      </Typography>
                    </Box>
                  )}
                  {Object.entries(productInfoApi?.data?.extra_fields || {}).map(
                    field => (
                      <>
                        <Box key={field.label}>
                          <Item label={field[0]} value={field[1]} />
                        </Box>
                      </>
                    )
                  )}
                </>
              )}
            </div>
          </div>
        </Box>
        <div style={{ marginTop: '50px', marginBottom: '24px' }}>
          <div className="containingtanks">Containing Tanks</div>
        </div>
        <TableHead columns={columns} />
      </Fixed>
      <Scrollable p={3}>
        {productsTanksApi?.isPending ? (
          <Loading m={4} />
        ) : (
          <TableBody
            columns={columns}
            data={tanksInfo}
            rowOnClick={row =>
              history.push(
                `/tanks&products/tank-details/${row.tankRegistrationNo}`
              )
            }
          />
        )}
      </Scrollable>
      <VisibilityModal
        isOpen={isOpenInvitation}
        toggleModal={toggleModalInvitation}
        data={data}
        entityType="TANK"
      />
      <Modal visible={isOpenMatcher} onClose={onCloseMatcher}>
        <Typography color="primary" style={{ fontWeight: '700' }}>
          TMS Number
        </Typography>
        <AutoCompleteInput
          flex="0.5"
          label="My Product"
          value={values.myProduct}
          api={{
            src: productAPI.list,
            params: {},
          }}
          renderItem={option => `${option?.id} ${option?.name}`}
          {...additionalProps('myProduct')}
        />
        <TextInput
          placeholder="Enter one code per line"
          label="Enter matcher codes (one code per line)"
          onChange={e => {
            return handleOnChange({ name: 'code', value: e })
          }}
          error={errors.code}
          value={values.code}
          disabled={false}
          name="code"
          id="code"
          multiline
          multilineHeight="130px"
        />
        <Button
          style={{
            marginTop: '20px',
            marginRight: '15px',
            width: '100%',
          }}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={updateProductMatchingStrings.isPending}
        >
          {updateProductMatchingStrings.isPending ? (
            <Loading color="#FFF" />
          ) : (
            'Save'
          )}
        </Button>
      </Modal>
    </Container>
  )
}
