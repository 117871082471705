import React, { useState, useEffect } from 'react'
import {
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Modal from '../../../../Modal'
import TenantModal from './TenantModal'
import useAPILocal from '../../../../../hooks/useAPILocal'
import connectionsAPI from '../../../../../api/connections'
import LoadingIndicator from '../../../../Order/Tabs/Loading'
import { toast } from 'react-toastify'

function VisibilityModal({
  isOpen,
  toggleModal,
  data,
  entityType = 'PRODUCT',
}) {
  const [isOpenTenant, setIsOpenTenant] = useState(false)
  const sharedResources = useAPILocal(connectionsAPI.sharedResources)

  const toggleModalTenant = () => {
    setIsOpenTenant(!isOpenTenant)
  }
  const [value, setValue] = useState(null)

  useEffect(() => {
    setValue(data?.visibility)
  }, [data?.visibility])

  const handleChange = event => {
    setValue(event.target.value)
  }

  const handleSubmit = () => {
    sharedResources.request({
      entityIds: [entityType === 'TANK' ? data?.tankRegistrationNo : data?.id],
      visibility: value,
      entityType,
      partnerIds: [],
    }).then(() => {
      toggleModal()
      toast.success('Tenants added successfully')
    }).catch(error => {
      toast.error(error?.clientMessage || error?.message || 'Error')
    })
  }
  sharedResources.isResolved && window.location.reload(true)

  return (
    <>
      {sharedResources.isPending && <LoadingIndicator />}
      <Modal
        visible={isOpen}
        onClose={toggleModal}
        style={{ padding: '0px 0px 20px 20px' }}
      >
        <Typography
          color="primary"
          style={{ fontWeight: '600', fontSize: '18px' }}
        >
          Visibility
        </Typography>

        <RadioGroup
          aria-label="gender"
          name="visibility"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="PRIVATE"
            control={<Radio />}
            label="Private"
          />
          <FormControlLabel value="PUBLIC" control={<Radio />} label="Public" />
          <FormControlLabel
            value="ALL_PARTNERS"
            control={<Radio />}
            label="All Partners"
          />
          <FormControlLabel
            value="SELECTED_PARTNERS"
            control={<Radio />}
            label="Selected Partners"
          />
        </RadioGroup>
        {value === 'SELECTED_PARTNERS' && (
          <>
            <ol style={{ marginLeft: '30px' }}>
              {data?.sharedWith?.map(item => (
                <li>{item.tenantName} </li>
              ))}
            </ol>
            <h4
              style={{
                color: '#003366',
                textDecorationLine: 'underline',
                cursor: 'pointer',
                marginTop: '10px',
              }}
              onClick={toggleModalTenant}
            >
              + Add Tenants
            </h4>
          </>
        )}
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            variant="contained"
            color="primary"
            disabled={value === 'SELECTED_PARTNERS'}
            onClick={handleSubmit}
          >
            Done
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={toggleModal}
            style={{ marginRight: '10px' }}
          >
            Cancel
          </Button>
        </div>
        <TenantModal
          isOpen={isOpenTenant}
          toggleModal={toggleModalTenant}
          entityId={entityType === 'TANK' ? data?.tankRegistrationNo : data?.id}
          entityType={entityType}
        />
      </Modal>
    </>
  )
}

export default VisibilityModal
