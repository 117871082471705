import React, { useEffect, useMemo, useState } from 'react'
import { Button, Typography } from '@material-ui/core'
import Modal from '../../../../Modal'
import TenantModalList from './TenantModalList'
import withPaginationLocal from '../../../../../HOC/withPaginationLocal'
import tenantAPI from '../../../../../api/tenant'
import useAPILocal from '../../../../../hooks/useAPILocal'
import connectionsAPI from '../../../../../api/connections'
import { toast } from 'react-toastify'

function TenantModal({ isOpen, toggleModal, entityId, entityType }) {
  const tenantList = useAPILocal(tenantAPI.tenants)
  const sharedResources = useAPILocal(connectionsAPI.sharedResources)
  const [rowData, setRowData] = useState([])

  useEffect(() => {
    tenantList.request()
  }, [])

  const TenantModalListShow = useMemo(
    () => withPaginationLocal(connectionsAPI.getPartners, {})(TenantModalList),
    []
  )
  const handleSelectedRows = rows => {
    if (rows.length === 0) {
      setRowData([])
    } else {
      setRowData(rows)
    }
  }

  const handleSubmit = () => {
    const partnerIds = rowData.map(item => item?.partner?.tenant_id)
    sharedResources.request({
      entityIds: [entityId],
      visibility: 'SELECTED_PARTNERS',
      entityType,
      partnerIds,
    }).then(() => {
      toast.success('Tenants added')
      toggleModal()
    })
  }
  sharedResources.isResolved && window.location.reload(true)

  return (
    <>
      <Modal
        visible={isOpen}
        onClose={toggleModal}
        style={{
          padding: '15px 0px 0px 15px',
          width: '600px',
          maxWidth: '600px',
          height: '68vh',
        }}
        bodyStyle={{ height: '450px' }}
      >
        <Typography
          color="primary"
          style={{ fontWeight: '600', fontSize: '18px', marginBottom: '10px' }}
        >
          Tenants
        </Typography>
        <TenantModalListShow onSelectRows={handleSelectedRows} />
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            style={{
              marginTop: '20px',
              marginRight: '15px',
            }}
            variant="contained"
            color="primary"
            disabled={sharedResources.isPending}
            onClick={handleSubmit}
          >
            Add Tenant
          </Button>
          <Button
            style={{
              marginTop: '20px',
              marginRight: '15px',
            }}
            variant="outlined"
            color="primary"
            onClick={toggleModal}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default TenantModal
