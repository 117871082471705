import { useHistory } from 'react-router-dom'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react'
import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Select,
  Typography,
  withStyles,
} from '@material-ui/core'
import { toast } from 'react-toastify'
import EditIcon from '@material-ui/icons/Edit'
import tankAPILocal from '../../../api/tank'
import useAPILocal from '../../../hooks/useAPILocal'
import Container from '../../Reusable/Container'
import Fixed from '../../Reusable/Fixed'
import PageHeading from '../../Reusable/PageHeading'
import PageTitle from '../../Reusable/PageTitle'
import Scrollable from '../../Reusable/Scrollable'
import TextInput from '../../Reusable/Widgets/TextInput'
import SelectInput from '../../Reusable/Widgets/SelectInput'
import Steps, { steps } from './Steps'
import AutoCompleteInput from '../../Reusable/Widgets/AutoCompleteInput'
import useForm from '../../../hooks/useForm'
import DateInput from '../../Reusable/Widgets/DateInput'
import CheckBoxInput from '../../Reusable/Widgets/CheckBoxInput'
import { modifyFields } from './steps/utils'
import useStateValue from '../../../hooks/useStateValue'
import Loading from '../../Loading'
import Validation from '../../../validations/Validation'
import routes from '../../../routes/route_contants'
import Modal from '../../Modal/index'
import BulkUploadField from '../../fieldsUpdating'
import productAPI from '../../../api/product'

const tankFields = step => {
  return (
    step === 'all' ? steps?.map(e => e.data).flat(1) : steps[step].data
  ).reduce((acc, e) => {
    acc = [...acc, ...e.children]
    return acc
  }, [])
}

const validationSchema = currentStep => {
  return tankFields(currentStep).reduce((acc, e) => {
    if (e.validations) acc[e.name] = e.validations
    return acc
  }, {})
}

const initValues = tankFields('all').reduce((acc, e) => {
  acc[e.name] = e.initial
  return acc
}, {})

const InputTypes = {
  text: { Component: TextInput },
  select: {
    Component: SelectInput,
    props: {
      SelectComponent: withStyles({ icon: { marginRight: 13 } })(Select),
    },
  },
  check: { Component: CheckBoxInput },
  autocomplete: { Component: AutoCompleteInput },
  date: { Component: DateInput },
}

function TankAdd(props) {
  const mode = props.location?.state?.mode || 'add'

  const oldData = props.location?.state?.details || {}
  const [currentStep, setCurrentStep] = useState(0)
  const [errorSteps, setErrorSteps] = useState(steps?.map(step => false))
  const initialLoad = useRef(true)
  const history = useHistory()
  const [fileUploadStatus, setFileUploadStatus] = useState('idle')
  const [isOpen, setIsOpen] = useState(false)
  const [fileExtension, setFileExtension] = useState('xlsx')
  const [currentFile, setCurrentFile] = useState('')
  const [SDSfile, setSDSfile] = useState(null)
  const [edit, setEdit] = useState(false)
  const [errorMsg, setErrorMsg] = useState()
  const toggleModal = () => {
    setIsOpen(true)
  }
  const onClose = () => {
    setIsOpen(false)
    setCurrentFile('')
    setFileUploadStatus('idle')
  }
  const mimes = {
    csv: 'application/csv',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xls: 'application/vnd.ms-excel',
  }

  const handleFileChange = file => {
    const ext = file.name.match(/[^\\]*\.(\w+)$/)[1]
    setFileExtension(ext)
    if (!(ext in mimes)) {
      alert('Upload a file with acceptable formats (.xlsx, .csv)')
      return
    }
    setCurrentFile(file)
  }

  const uploadBulkTanks = async () => {
    try {
      setFileUploadStatus('pending')

      tankAPILocal
        .createBulkTank(currentFile)
        .then(response => {
          if (response.data.status === 'error') {
            setFileUploadStatus('rejected')
            setErrorMsg(response.data.message)
          } else setFileUploadStatus('resolved')
        })
        .catch(err => {
          console.log(err)
        })
    } catch (err) {
      setFileUploadStatus('rejected')
    }
  }

  const bulKModal = (
    <Modal visible={isOpen} onClose={onClose}>
      <>
        {(fileUploadStatus === 'idle' || fileUploadStatus === 'rejected') && (
          <div>
            <label className="file">
              <input
                type="file"
                id="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                aria-label="File browser example"
                onChange={e => handleFileChange(e.target.files[0])}
              />
              <span className="file-custom"></span>
            </label>
            {currentFile && (
              <button
                type="button"
                disabled={fileUploadStatus === 'pending'}
                onClick={uploadBulkTanks}
                className="btn-primary btn-block my-1"
              >
                Upload
                {fileUploadStatus === 'pending' && (
                  <CircularProgress
                    style={{ marginLeft: 10 }}
                    size={15}
                    mode="indeterminate"
                  />
                )}
              </button>
            )}
          </div>
        )}
        <BulkUploadField type="tank" />
        {fileUploadStatus === 'rejected' && (
          <div style={{ textAlign: 'center' }}>
            <p id="alert-dialog-slide-description" style={{ color: 'red' }}>
              Something went wrong ! Try again
            </p>
            <p id="alert-dialog-slide-description" style={{ color: 'red' }}>
              {errorMsg}
            </p>
          </div>
        )}
        {fileUploadStatus === 'pending' && (
          <div className="dialog-success-message" style={{ width: '100%' }}>
            <div>
              <CircularProgress thickness={4} size="100px" />
            </div>
            <div>Bulk Tank details are being uploaded</div>
          </div>
        )}
        {fileUploadStatus === 'resolved' && (
          <div
            className="dialog-success-message"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'cente',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <div>
              <CheckCircleOutlineIcon
                style={{ fontSize: '48px', color: 'mediumseagreen' }}
              />
            </div>
            <br />
            <div>Data Updated Succesfully </div>
          </div>
        )}
      </>
    </Modal>
  )

  const [
    {
      user: { user_info },
    },
  ] = useStateValue()
  const createTank = useAPILocal(tankAPILocal.create)
  const updateTankEdit = useAPILocal(tankAPILocal.updateInfo)

  const uploadSDSFile = useAPILocal(tankAPILocal.uploadFile)
  const getSDSFile = useAPILocal(tankAPILocal.getFile)
  const [sdsFiles, setSdsFiles] = useState()
  const goToNext = () => {
    if (currentStep < 5) setCurrentStep(prev => prev + 1)
  }

  const createTankFinal = async result => {
    const newResult = { ...result, primaryContact: result.primaryContact?.id }
    if (mode === 'add') {
      await createTank
        .request({
          ...modifyFields(newResult),
        })
        .then(res => {
          if (SDSfile) {
            uploadSDSFile.request({
              tankId: res?.data?.tankRegistrationNo,
              data: SDSfile,
            })
          }
          toast.success('Tank created successfully')
          history.goBack()
        })
        .catch(error => {
          toast.error(error?.clientMessage || error?.message || 'Error')
        })
    } else if (mode === 'update') {
      await updateTankEdit
        .request(props.match.params?.tank_registration_no, {
          ...modifyFields(newResult),
          tenant: user_info?.tenant?.tenant_id,
        })
        .then(res => {
          if (SDSfile)
            uploadSDSFile.request({
              tankId: props.match.params?.tank_registration_no,
              data: SDSfile,
            })
          toast.success('Tank updated successfully')
          history.goBack()
        })
        .catch(error => {
          toast.error(error?.clientMessage || error?.message || 'Error')
        })
    }
  }

  const { values, errors, handleOnChange, handleSubmit, setValues } = useForm(
    { ...initValues, ...oldData },
    currentStep === 5 ? validationSchema('all') : validationSchema(currentStep),
    result => {
      if (currentStep < 5) goToNext()
      else {
        createTankFinal(result)
      }
    }
  )

  const additionalProps = name => {
    const addProps = {
      value: values[name],
      error: errors[name],
      onChange: newValue => {
        handleOnChange({
          name,
          value: newValue,
        })
      },
    }
    switch (name) {
      case 'chemical':
        addProps.disabled = values.products?.length !== 0
        steps[0].data[0].children[0].validations =
          values.products?.length !== 0 ? undefined : ['required']
        break
      case 'products':
        addProps.disabled = values.chemical !== '' && values.chemical !== null
        steps[0].data[1].children[0].validations =
          values.chemical !== '' ? undefined : ['required']
        if (values.products?.length !== 0) {
          addProps.api = {
            src: productAPI.list,
            params: {
              chemicalId: values.products?.[0]?.chemical?.id,
              selectedProducts: values.products
                ?.map(product => product.id)
                .join(','),
            },
          }
        }
        break
      default:
        break
    }
    return addProps
  }

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false
      return
    }
    const validationStatus = Validation.getValidationStatus(
      steps?.map((step, index) => {
        return { data: values, validation: validationSchema(index) }
      })
    )

    setErrorSteps(validationStatus)
  }, [currentStep])

  useEffect(() => {
    if (createTank.isResolved || updateTankEdit.isResolved) {
      setTimeout(() => {
        history.push(routes.TANKS_LIST)
      }, 1000)
    }
  }, [createTank.isResolved, updateTankEdit.isResolved])

  useEffect(() => {
    getSDSFile
      .request(props.match.params?.tank_registration_no)
      .then(response => setSdsFiles(response?.data))
  }, [])

  const uploadNewSDSFile = async e => {
    const file = e.target.files[0]
    setSDSfile(file)
  }

  const onMarkerDragEnd = coord => {
    const { latLng } = coord
    setValues({
      ...values,
      latitude: latLng.lat() || '',
      longitude: latLng.lng() || '',
    })
  }

  return (
    <Container pt={3} px={3}>
      <Fixed>
        <PageHeading>
          <PageTitle
            title={mode === 'add' ? 'Add Tank' : 'Edit Tank'}
            backButton
          />
          <Button variant="outlined" color="primary" onClick={toggleModal}>
            Bulk Upload
          </Button>
        </PageHeading>
      </Fixed>
      {bulKModal}
      <Scrollable mt={2} style={{ height: '100%' }}>
        <Box display="flex" height="100%">
          <Box overflow="auto" height="100%">
            <Steps
              onChange={setCurrentStep}
              errorSteps={errorSteps}
              current={currentStep}
            />
          </Box>
          <Box mx={6} px={2}>
            <Divider orientation="vertical" />
          </Box>
          <Box flex="1" mr={2} px={1} pb={2} overflow="auto" height="100%">
            {createTank?.isPending || updateTankEdit.isPending ? (
              <Loading />
            ) : (
              <div>
                {steps[currentStep].data?.map((row, index) => {
                  if (!row.visible(values)) {
                    return null
                  }
                  const key = `${row.key}-${index}`
                  return (
                    <>
                      <Box key={key} display="flex" className="form-row">
                        {row.children?.map((child, i) => {
                          const Type = InputTypes[child.type]
                          return (
                            <Type.Component
                              key={child.name}
                              flex="1"
                              {...child}
                              {...additionalProps(child.name)}
                              {...Type.props}
                            />
                          )
                        })}
                      </Box>
                      {currentStep === 0 && index === 0 && (
                        <Box style={{ marginTop: 15 }}>OR</Box>
                      )}
                    </>
                  )
                })}
                {currentStep === 0 && (
                  <div>
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: '14px',
                        marginBottom: ' 5px',
                        minHeight: '20px',
                        marginTop: '20px',
                      }}
                    >
                      Link to SDS
                    </Typography>
                    {!edit && sdsFiles && sdsFiles?.length !== 0 && (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography>
                          {sdsFiles[sdsFiles?.length - 1]?.name}
                        </Typography>
                        <Button size="small" onClick={() => setEdit(true)}>
                          <EditIcon style={{ fontSize: 18 }} />
                        </Button>
                      </div>
                    )}
                    {mode === 'add' ||
                      (edit && (
                        <div display="flex">
                          <input
                            type="file"
                            id="file"
                            accept={`${mimes.pdf}`}
                            onChange={e => uploadNewSDSFile(e)}
                          />
                        </div>
                      ))}
                    {mode === 'add' && (
                      <div display="flex">
                        <input
                          type="file"
                          id="file"
                          accept={`${mimes.pdf}`}
                          onChange={e => uploadNewSDSFile(e)}
                        />
                      </div>
                    )}
                    {mode === 'update' && sdsFiles?.length === 0 && (
                      <div display="flex">
                        <input
                          type="file"
                          id="file"
                          accept={`${mimes.pdf}`}
                          onChange={e => uploadNewSDSFile(e)}
                        />
                      </div>
                    )}
                  </div>
                )}
                {currentStep === 0 && (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                      }}
                    >
                      <TextInput
                        name="latitude"
                        placeholder="Latitude"
                        label="Latitude"
                        onChange={value => {
                          return handleOnChange({
                            name: 'latitude',
                            value,
                          })
                        }}
                        value={values.latitude}
                        error={errors.latitude}
                      />
                      <TextInput
                        name="longitude"
                        placeholder="Longitude"
                        label="Latitude"
                        onChange={value => {
                          return handleOnChange({
                            name: 'longitude',
                            value,
                          })
                        }}
                        value={values.longitude}
                        error={errors.longitude}
                      />
                    </div>
                    <Map
                      containerStyle={{
                        height: '30vh',
                        position: 'relative',
                        margin: '7px',
                        marginTop: '10px',
                        width: '96%',
                      }}
                      zoom={15}
                      initialCenter={{
                        lat: values?.latitude || 40.854885,
                        lng: values?.longitude || -88.081807,
                      }}
                      center={{
                        lat: values?.latitude || 40.854885,
                        lng: values?.longitude || -88.081807,
                      }}
                      google={window.google}
                      streetView
                    >
                      <Marker
                        draggable
                        onDragend={(t, map, coord) => onMarkerDragEnd(coord)}
                        position={{
                          lat: values?.latitude || 40.854885,
                          lng: values?.longitude || -88.081807,
                        }}
                        icon={{
                          url: 'https://i.pinimg.com/originals/25/62/aa/2562aacd1a4c2af60cce9629b1e05cf2.png',
                          scaledSize: new window.google.maps.Size(40, 40),
                        }}
                      />

                      <InfoWindow>
                        <div style={{ height: '30px' }}>
                          <p>cdc</p>
                        </div>
                      </InfoWindow>
                    </Map>
                  </div>
                )}
              </div>
            )}
          </Box>
        </Box>
      </Scrollable>
      <Fixed>
        <Box py={1} display="flex" justifyContent="flex-end">
          <Box mr={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
          </Box>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {currentStep < 5 ? 'Next' : 'Finish'}
          </Button>
        </Box>
      </Fixed>
    </Container>
  )
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  Loading,
})(TankAdd)
