import { Box, Button, Checkbox } from '@material-ui/core'
import { toast } from 'react-toastify'
import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../../Modal'
import useForm from '../../../hooks/useForm'
import Loading from '../../Loading'
import TextInput from '../../Reusable/Widgets/TextInput'
import useAPILocal from '../../../hooks/useAPILocal'
import tenantAPI from '../../../api/tenant'
import CheckBoxInput from '../../Reusable/Widgets/CheckBoxInput'

const init = {
  email: '',
  companyName: '',
  companyEin: '',
}

const validationSchema = {
  companyName: ['required:Company name is required'],
  companyEin: ['required:Company EIN is required'],
  email: [
    'required:Email is required',
    'email:Email field is not a valid email address',
  ],
}

const Invite = props => {
  const { isOpen, toggleModal } = props
  const inviteApi = useAPILocal(tenantAPI.inviteTenant)

  const apiRequest = async (
    companyName,
    companyEin,
    email,
    seller,
    carrier,
    buyer
  ) => {
    const result = await inviteApi.request({
      adminEmail: email.toLowerCase(),
      tenantName: companyName,
      tenantEin: companyEin,
      seller: seller || false,
      carrier: carrier || false,
      buyer: buyer || false,
      active: true,
    })
    if (!result.ok) {
      toast.error(result?.data?.clientMessage || result?.data?.message || 'Error sending invitation')
      return null
    }
    resetForm()
    if (!result.isRejected) {
      toast.success('Invitation sent successfully')
      toggleModal('invited')()
    }
  }

  const { errors, handleOnChange, resetForm, values, handleSubmit } = useForm(
    init,
    validationSchema,
    result => {
      if (
        result?.companyName &&
        result?.companyEin &&
        result?.email &&
        errors?.email !== '' &&
        !errors?.companyName !== ''
      ) {
        apiRequest(
          result?.companyName,
          result?.companyEin,
          result?.email,
          result?.seller,
          result?.carrier,
          result?.buyer
        )
      }
    }
  )

  return (
    <>
      <Modal visible={isOpen} onClose={toggleModal(false)} title="Add Tenant">
        <TextInput
          name="companyName"
          placeholder="Enter Company Name"
          label="Company Name"
          onChange={value => {
            return handleOnChange({
              name: 'companyName',
              value,
            })
          }}
          value={values.companyName}
          error={errors.companyName}
        />
        <TextInput
          name="companyEin"
          placeholder="Enter Company EIN"
          label="Company EIN"
          onChange={value => {
            return handleOnChange({
              name: 'companyEin',
              value,
            })
          }}
          value={values.companyEin}
          error={errors.companyEin}
        />
        <TextInput
          name="email"
          placeholder="Enter Email"
          label="Email"
          onChange={value => {
            return handleOnChange({ name: 'email', value })
          }}
          value={values.email}
          error={errors.email}
        />
        <CheckBoxInput
          label="Seller"
          value={values.seller}
          onChange={value => {
            return handleOnChange({ name: 'seller', value })
          }}
          checklist
        />
        <CheckBoxInput
          label="Carrier"
          value={values.carrier}
          onChange={value => {
            return handleOnChange({ name: 'carrier', value })
          }}
          other
          checklist
        />
        <CheckBoxInput
          label="Buyer"
          value={values.buyer}
          onChange={value => {
            return handleOnChange({ name: 'buyer', value })
          }}
          other
          checklist
        />

        {/* <div className="text-footer-modal">
        Company name entered here will be replaced by the registered name
        entered here by the company admin.
      </div> */}
        {inviteApi.isPending && <Loading />}
        {!inviteApi.isPending && (
          <Button
            style={{ marginTop: '20px' }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
      </Modal>
    </>
  )
}

Invite.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
}

export default Invite
